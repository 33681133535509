import React, { useEffect, useRef, useState } from "react";

export default function CountdownTimerReact({ hideCountdownTimer, meeting_link, getZoomLink, }) {
  const Ref = useRef(null);

  // The state for our timer
  const [timer, setTimer] = useState("05:00");

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds, hours } = getTimeRemaining(e);
    console.log(minutes, "minutes");
    let array = [3, 2, 1]
    if (array.includes(minutes) && (meeting_link == null || meeting_link == "")) {
      // getZoomLink();
    }
    if (total >= 300) {
      setTimer(
        // (hours > 9 ? hours : '0' + hours) + ':' +
        (minutes > 9 ? minutes : "0" + minutes) +
        ":" +
        (seconds > 9 ? seconds : "0" + seconds)
      );
    }
    if (total === 0) {
      clearInterval(Ref.current);
      hideCountdownTimer();
    }
  };

  const clearTimer = (e) => {
    setTimer("5:00");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 300);
    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  return (
    <div className="font-size-28 mt-4 mb-4 text-center">
      <p >{timer}</p>
    </div>
  );
}
