import React, { useEffect, useState } from "react";
import MultiDatePicker from "react-multi-date-picker";
import moment from "moment";
import "./goondemand.css";
import { useSelector } from "react-redux";
import Questions from "./questionsbox";
import ScheduledetailsModal from "../../admin/ondemandupdates/scheduledetails";
import {
  addMediumToOnDemandQueue,
  bookOnDemandSession,
  checkMediumInOnDemandQueue,
  getMediumOndemandEvents,
  getZoomLinkOnDemand,
  removeMediumFromOnDemandQueue,
} from "../../../services/medium";
import AlredyJoined from "./alreadyJoined";
import ConfirmOut from "./confirmOut";
import { el } from "date-fns/locale";
import Pusher from "pusher-js";
import ReminderPopUp from "./reminderPopup";
import { useNavigate } from "react-router-dom";
import CountdownTimerReact from "./countdown";

export default function GoOnDemandPractice() {
  const { timezone, id } = useSelector((state) => state.user.user);
  const [loaderType, setloaderType] = useState("1");
  const [inputValue, setInputValue] = useState("");
  const [info, setInfo] = useState();
  const [Index, setIndex] = useState(0);
  const [eventsList, setEventList] = useState([]);
  const [visibleCount, setVisibleCount] = useState(5);
  const [values, setValues] = useState([]);
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const [timer, setTimer] = useState(null);
  const [activeEvent, setActiveEvent] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [time, setTime] = useState(0);

  const [ondemandStatus, setOndamandStatus] = useState(false);
  const [counterStatus, setCounterStatus] = useState(false);
  const [shoHostStatus, setshoHostStatus] = useState(false);
  const [meeting_link, setmettingLink] = useState("");
  const [meetingLinkStatus, setmettingLinkStatus] = useState(false);
  const [appoinmentId, setAppoinmentId] = useState("");
  const loadMoreItems = () => {
    setVisibleCount((prevCount) => prevCount + 5); // Load next 8 items
  };
  const [message, setMessage] = useState("");
  let interval;
  const navigate = useNavigate();

  const day = (day) => moment(day).format("ddd"); // Short day name (e.g., 'Fri')
  const month = (month) => moment(month).format("MMM"); // Short month name (e.g., 'Sep')
  const date = (date) => moment(date).format("DD");
  const times = (time) => moment(time, "HH:mm").format("hh:mm A");

  const getEventList = (from, to, keyword) => {
    setVisibleCount(5);
    getMediumOndemandEvents(from, to, keyword)
      .then((data) => {
        const events = Object.values(data.data.data).flat();

        let newEvents = events.map((info) => {
          return {
            id: info.id,
            description: info.description,
            ondemand_session_category_id: info.ondemand_session_category_id,
            name: info.title,
            // time: times(info.start_time),
            time: info.start_time,

            monthname: month(info.date),
            date: date(info.date),
            day: day(info.date),
            subtext: info.sub_text,
            backupdate: info.date,
            end_time: info.end_time,
            showReminder: checkTime(info.date, info.start_time, info.end_time),
          };
        });

        setEventList(newEvents);
        runIntervalFunc(newEvents);
      })
      .catch(() => { });
  };

  const checkOldTime = (datee, timee) => {
    let status = false;
    // Define the target date and time using Moment.js
    const targetDate = moment(`${datee} ${timee}`, "YYYY-MM-DD hh:mm A"); // 6:00 PM is 18:00 in 24-hour format
    // Get the current date and time
    const currentDate = moment(
      moment().format("YYYY-MM-DD hh:mm A"),
      "YYYY-MM-DD hh:mm A"
    );

    // Check if the current time is past the target time
    if (currentDate.isSame(targetDate)) {
      status = true;
    } else {
      status = false;
    }
    return status;
  };

  const runIntervalFunc = (event = []) => {
    interval = setInterval(() => {
      let list = eventsList.length > 0 ? eventsList : event;
      let newlist = list.filter((item) => {
        if (!checkOldTime(item.backupdate, item.end_time)) {
          return item;
        } else {
          return;
        }
      });
      let newEvent = newlist.map((item) => {
        // console.log(item.backupdate, item.time);
        let status = checkTime(item.backupdate, item.time, item.end_time);
        return {
          ...item,
          showReminder: status,
        }; // Example: Incrementing each value
      });
      // console.log(newEvent, "newEvent")
      setEventList(newEvent);
    }, 6000); // 60 seconds = 60000 ms
    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  };
  useEffect(() => {
    removeMediumFromQueue(45);
    localStorage.setItem("ondemandLoader", "1");
    const from = moment().format("YYYY-MM-DD");
    const to = moment()
      .add(30, "days")
      .format("YYYY-MM-DD");
    setFromTime(from);
    setToTime(to);

    getEventList(from, to, "");
    // setEventList(array)
  }, []);
  const getClientProfileDetails = (item, index) => {
    setIndex(index);
    setInfo(item);
    window.loadModal2("#ScheduledetailsModal", "show");
  };
  const handleValueChange = (newValues) => {
    if (newValues) {
      setValues(newValues);
      let name = {};
      if (newValues.length === 2) {
        const startDate = newValues[0].format("YYYY-MM-DD");
        const endDate = newValues[1].format("YYYY-MM-DD");
        // name["created_at"] = `${startDate},${endDate}`; // Fix the assignment
        setFromTime(startDate);
        setToTime(endDate);
        getEventList(startDate, endDate, "");
      }
      // if(newValues.length === 1){
      //   console.log( newValues[0].format("YYYY-MM-DD"))
      //   const startDate = newValues[0].format("YYYY-MM-DD");
      //   getEventList('', '','',startDate)
      // }
    }
  };
  const inputChanged = (e) => {
    let value = e.target.value;
    setInputValue(e.target.value);

    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      if (e.target.value.length > 2 && e.target.value.length === 0) {
        getEventList(fromTime, toTime, value);
      }
    }, 2000);

    setTimer(newTimer);
  };
  const checkTime = (date, time, end) => {
    const dateToCheck = moment(date); // Replace with the date you want to check
    const isToday = dateToCheck.isSame(moment(), "day");
    // console.log(isToday, date)

    if (isToday) {
      // Convert input to moment
      let currentTime = moment().format("YYYY-MM-DD HH:mm");
      let endtime = moment(`${date} ${end}`, "YYYY-MM-DD hh:mm A").format(
        "YYYY-MM-DD HH:mm"
      );

      const inputMoment = moment(
        `${date} ${time}`,
        "YYYY-MM-DD hh:mm A"
      ).format("YYYY-MM-DD HH:mm");
      const time1 = moment(currentTime, "YYYY-MM-DD HH:mm");
      const time2 = moment(inputMoment, "YYYY-MM-DD HH:mm");

      const time3 = moment(endtime, "YYYY-MM-DD HH:mm");
      const minutes = time3.diff(time1, "minutes");
      const minutesDifference = time1.diff(time2, "minutes");
      let status = minutesDifference >= 0 && minutes > 0;

      return status;
    } else {
      return isToday;
    }
    return;

    // Check if the difference is between 0 and 10 (inclusive) or exactly 0
  };
  const openReminderPopup = (info) => {
    setActiveEvent(info);
    window.loadModal2("#ReminderPopUp", "show");
  };
  const checkMediumInOnDemand = (info, check) => {
    setTime(0);

    setOndamandStatus(false);
    checkMediumInOnDemandQueue()
      .then((data) => {
        if (!data.data.data) {
          if (check == "other") {
            addMediumQueue();
          } else {
            setActiveEvent(info);
            localStorage.setItem("ondemandLoader", "2");
            setloaderType("2");
          }
        } else {
          window.loadModal2("#AlredyJoined", "show");
        }
      })
      .catch(() => { });
  };

  const addMediumQueue = () => {
    let event_id = activeEvent.id;

    addMediumToOnDemandQueue(event_id)
      .then((data) => {
        if (data.data.data) {
          localStorage.setItem("ondemandLoader", "3");
          setloaderType("3");
          setTime(time + 1);
          // bookSession will start here
        }
      })
      .catch(() => { });
  };

  const removeMediumFromQueue = () => {
    // removeMediumFromOnDemandQueue(activeEvent.id)
    removeMediumFromOnDemandQueue()
      .then((data) => { })
      .catch(() => { });
  };
  useEffect(() => {
    if (time > 0 && !ondemandStatus) {
      let status = checkOldTime(activeEvent.backupdate, activeEvent.end_time);
      if (status) {
        setOndamandStatus(true);
        removeMediumFromQueue();
        localStorage.setItem("ondemandLoader", "1");
        setloaderType("1");
        return;
      }

      bookSession();
    }
  }, [time, ondemandStatus]);
  const bookSession = (event_id) => {
    let body = {
      event_id: activeEvent.id,
    };
    bookOnDemandSession(body)
      .then((data) => {
        // if (!data.data.status) {
        setTime(time + 1);
        // }
        // console.log(data, "data");
      })
      .catch(() => { });
  };
  const startLoaderNow = () => {
    checkMediumInOnDemand(activeEvent, "other");
    setTime(0);
    // addMediumQueue();
  };

  useEffect(() => {
    const handleBeforeUnload = (event, confirmExit) => {
      let ondemandLoader = localStorage.getItem("ondemandLoader");
      if (ondemandLoader == "3") {
        event.preventDefault();
      }
    };
    const handleUnload = () => {
      removeMediumFromQueue();
      console.log("User chose to leave the page");
      // You can run any final cleanup code here before the page unloads
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
    };
  }, []);
  useEffect(() => {
    Pusher.logToConsole = false;
    var pusher = new Pusher("823ab72ed6ecd563b8c8", {
      cluster: "ap2",
    });

    var channel = pusher.subscribe("ondemand-booking");
    channel.bind("App\\Events\\OnDemandBooking", function (data) {
      if (data.appointment !== undefined) {
        if (
          data.appointment.medium_id == id ||
          data.appointment.user_id == id
        ) {
          // console.log(data, "data");
          setTime(0);
          setCounterStatus(true);
          setOndamandStatus(true);
          setMessage(data.message);
          setAppoinmentId(data.appointment.id);
          localStorage.setItem('appoinmentId', data.appointment.id)
          setmettingLink(data.appointment.meeting_link);
          if (data.appointment.meeting_link == null || data.appointment.meeting_link == "") {
            getZoomLink(data.appointment.id)
          }
          console.log(data.appointment.meeting_link, "data.meeting_link");
          setloaderType("4");

          if (data.appointment.medium_id == id) {
            setshoHostStatus(true);
          } else {
            setshoHostStatus(false);
          }
        }
      }
    });
    // pusher.connection.bind('error', (err) => {
    //   console.error('Pusher error: ', err);
    //   // setError('An error occurred while connecting to Pusher');
    // });

    // Cleanup on component unmount
    // return () => {
    //   channel.unbind_all();
    //   pusher.unsubscribe('my-channel');
    // };
  }, []);
  // useEffect(() => {
  //   // if (loaderType == "4") {

  //   // }
  //   if (loaderType == "3") {

  //     // setTimeout(() => {
  //     //   removeMediumFromQueue()
  //     //   localStorage.setItem("ondemandLoader", "1")
  //     //   setloaderType('1');
  //     // }, 600000)
  //   }
  // }, [loaderType])
  const getZoomLink = (id) => {
    getZoomLinkOnDemand(id).then((data) => {
      console.log(data.data)
      if (data.data.success == "Appointment Zoom Link") {
        setmettingLink(data.data.data);
      }
    })
  }
  const hideCountdownTimer = () => {
    setCounterStatus(false);

    // window.open(meetingUrl, "_blank");
  };

  useEffect(() => {
    // // if (meetingLinkStatus) {
    // Pusher.logToConsole = true;
    // var pusher = new Pusher("823ab72ed6ecd563b8c8", {
    //   cluster: "ap2",
    // });

    // var channel = pusher.subscribe("zoom-link");
    // channel.bind("App\\Events\\ZoomLinkUpdate", function (data) {

    //   // setTimeout(() => {
    //     console.log(meeting_link, "meeting_link")
    //     console.log(data.appointment_id, "data.appointment_id")
    //     console.log(data.meeting_link, "data.meeting_link")
    //     console.log(appoinmentId, "old appoinmentId")
    //     if (parseInt(data.appointment_id) == parseInt(localStorage.getItem('appoinmentId'))) {
    //       if (meeting_link == "" || meeting_link == null) {

    //         setmettingLink(data.meeting_link);
    //         setmettingLinkStatus(false);
    //         localStorage.removeItem('appoinmentId');
    //       }
    //     }
    //   // }, 5000);

    // });
    // // }
  }, []);

  return (
    <>
      {loaderType == "3" && (
        <>
          <div
            onClick={() => {
              setloaderType("1");
              removeMediumFromQueue();
              setTime(0);
              setOndamandStatus(true);
            }}
            className="back-btn-appointments pointer"
          >
            <div className="bck">
              <img src="images/black-erow.png" alt="back" />
              <p className="margin-unset"> Back</p>
            </div>
          </div>
          <h4 className="text-center mb-3 mt-3">
            {/* {console.log(activeEvent)} */}
            {
              (activeEvent !== undefined && activeEvent.name)}
          </h4>
          <h4 className="text-center  pl-5 pr-5">Hold on, we are searching for your match. Do not leave this screen until we let you know you've been matched or the matching period ends.</h4>

          <video className="loader-video" autoPlay loop>
            <source src={"/localImages/AroundTheWorld.mp4"} type="video/mp4" />
          </video>
        </>
      )}
      {loaderType == "1" && (
        <div className="container-fluid ">
          <div className="dashboard ">
            <div className="row">
              <div className="col-md-12">
                <div className="middle-screen-name w-100 font-size-22 mt-2 mb-2">
                  <b>
                    Timezone: {timezone !== undefined && timezone}&nbsp; { }
                  </b>
                </div>
                <div className="ondemandpractice-wrapper">
                  <div></div>
                  <div className=" justify-content-between mb-2 mt-3 d-flex-flex-wrap-wrap">
                    <div className="search-box search-container ">
                      <input
                        value={inputValue}
                        onChange={inputChanged}
                        className="search-input"
                        // type={filterValues === "created_at" ? "date" : "text"}
                        placeholder="Search"
                      />
                    </div>
                    {/* <div className="filter-container-box ">
                      Dates
                    </div> */}
                    <div className="filter-wrapper filter-container-box">
                      <MultiDatePicker
                        style={{ height: "45px" }}
                        className="search-input "
                        numberOfMonths={2}
                        disableMonthPicker
                        disableYearPicker
                        range
                        value={values}
                        onChange={handleValueChange}
                        placeholder="Select date range"
                      // maxDate={new Date()}
                      />
                    </div>
                  </div>
                  <div>
                    <h3 className="ondemandpractice-heading mt-3">Events</h3>
                  </div>
                  <div>
                    <ul className="godemantevent-box-list">
                      {eventsList.slice(0, visibleCount).map((item, index) => (
                        <li>
                          <div className="godemantevent-box">
                            <div className="godemantevent-internal-box d-flex-flex-wrap-wrap">
                              <div className="godemantevent-date-box">
                                <div className="month-name">
                                  <span>{item.monthname}</span>
                                </div>
                                <div className="month-date mt-1">
                                  <span>{item.date}</span>
                                </div>
                              </div>
                              <div
                                onClick={() =>
                                  getClientProfileDetails(item, index)
                                }
                                className="godemantevent-date-box width-55 pointer"
                              >
                                <div>
                                  <span className="ondemandpractice-date-box-day">
                                    <span aria-hidden="true">
                                      <span>{item.day}</span>
                                      <span class="sc-1idcr5x-1 dieHWG">
                                        <span>{item.time}</span> -{" "}
                                        <span>
                                          {item.end_time} (Matching period)
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </div>
                                <div>
                                  <span class="sc-fyofxi-6 ivIeWB">
                                    <span class="sc-fyofxi-5 gJmuwa">
                                      {item.name}
                                    </span>
                                    {/* <span class="sc-fyofxi-5 gJmuwa">Resch Center</span> */}
                                  </span>
                                  <span class="sc-fyofxi-7 jWLmQR">
                                    <span class="sc-fyofxi-5 gJmuwa">
                                      {item.subtext}
                                    </span>
                                  </span>
                                </div>
                              </div>

                              <div className="godemantevent-date-box text-right">
                                {/* () => { } */}
                                <a
                                  onClick={
                                    item.showReminder
                                      ? () => checkMediumInOnDemand(item)
                                      : () => openReminderPopup(item)
                                  }
                                  href="#"
                                  class="width-175-px indexstyles__StyledButton-sc-83qv1q-0 jdbotF sc-1qeub3n-4 lmaHQg"
                                >
                                  <span class="indexstyles__FlexWrapper-sc-83qv1q-1 kCQEIv">
                                    <span class="indexstyles__Text-sc-83qv1q-2 jHTUWf">
                                      <span class="sc-1qeub3n-6 iwMkwK">
                                        <span class="sc-1qeub3n-5 eTlzst">
                                          {item.showReminder
                                            ? "Go On Demand"
                                            : "Remind Me"}
                                        </span>
                                      </span>

                                      <svg
                                        class="BaseSvg-sc-yh8lnd-0 ChevronIcon___StyledBaseSvg-sc-1y4em6t-0 ckLyyv sc-1qeub3n-2 bcqnRP"
                                        viewBox="0 0 24 24"
                                        width="1.5em"
                                        height="1.5em"
                                        aria-hidden="true"
                                        focusable="false"
                                      >
                                        <path d="M3.47 8.26 4.53 7.2 12 14.67l7.47-7.47 1.06 1.06L12 16.8z"></path>
                                      </svg>
                                    </span>
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                      <div className="d-flex align-items-center justify-content-center mt-5">
                        <div
                          onClick={loadMoreItems}
                          className="width-175-px pointer indexstyles__StyledButton-sc-83qv1q-0 jdbotF sc-1qeub3n-4  d-flex align-items-center justify-content-center"
                        >
                          Load More
                        </div>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <CountdownTimer counterStatus={counterStatus} hideCountdownTimer={hideCountdownTimer} /> */}
        </div>
      )}
      {loaderType == "2" && (
        <Questions
          startLoaderNow={startLoaderNow}
          onCancelQuestions={() => setloaderType("1")}
        />
      )}
      {loaderType == "4" && (
        <div className="ondemandpractice-wrapper margin-auto min-height-500px mt-5">
          <div className="">
            <div className="godemantevent-box-list">
              <p className="text-center mt-5 font-size-26">
                {" "}
                Your Appointment Is Confirmed!
              </p>

              {shoHostStatus && (
                <h3 className="text-center mt-5 line-height-32px">
                  You are the Host,
                  <b className="text-underline">
                    {" "}
                    so be sure you are
                    <br /> logged into your Zoom
                  </b>{" "}
                  account to start
                  <br /> the session.
                </h3>
              )}
              <p className="text-center mt-4 font-size-19">{message}</p>
              {counterStatus && (
                <CountdownTimerReact hideCountdownTimer={hideCountdownTimer}  meeting_link={meeting_link} getZoomLink={getZoomLink}/>
              )}
              <p className="text-center mt-3 font-size-19">
                You can either joining the session now, or go to your
                appointments
                <br /> page to find the zoom link to join the session.
              </p>
            </div>
            <br />
            {console.log(meeting_link, "meeting_link")}
            {(meeting_link == "" || meeting_link == null || meeting_link == undefined) && <div className="text-center mb-3 mt-1">

              <b > Zoom Link is being created</b>
            </div>}
            <div className="d-flex align-items-center justify-content-center ">
              {/* {console.log(meeting_link, "meeting_link")} */}
              {/* {meeting_link !== "" && ( */}
              <a
                href={meeting_link !== "" && meeting_link !== null && meeting_link !== undefined ? meeting_link : 'javascript:void();'}
                target={meeting_link !== "" && meeting_link !== null && meeting_link !== undefined ? '_blank' : ''}
                // onClick={() => {

                //   setloaderType('1'); setTime(0);
                //   setOndamandStatus(false)
                // }}
                className={`${meeting_link !== "" && meeting_link !== null && meeting_link !== undefined ? '' : 'disabled-btn-join'} width-175-px pointer indexstyles__StyledButton-sc-83qv1q-0 jdbotF sc-1qeub3n-4  d-flex align-items-center justify-content-center`}
              >
                Join Now
              </a>
              {/* )} */}
              <div
                onClick={() => {
                  navigate("/appointments");
                  setloaderType("1");
                  setTime(0);
                  setOndamandStatus(false);
                }}
                className="width-175-px pointer indexstyles__StyledButton-sc-83qv1q-0 jdbotF-blacnk sc-1qeub3n-4  d-flex align-items-center justify-content-center"
              >
                My Appointments
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-center mt-5 go-ondemand-practice-hover">
              <div className="col-md-4 hoverContent">
                <div className="hover-container d-flex ">
                  Having issues connecting?{" "}
                  <p className="hover-target" tabindex="0">
                    <img src="/images/qu.png" className="ondemant-i-btn" />
                  </p>
                  <aside className="hover-popup-r">
                    <p className="mainpre">
                      1. Please make sure you are logged in to the correct Zoom
                      account.
                    </p>
                    <p className="mainpre">
                      2. Click or copy and paste the Zoom link from My
                      Appointments.
                    </p>
                    <p className="mainpre">
                      3. Check your email for Booking Reservation for the
                      meeting Zoom link.
                    </p>

                    <p className="mainpre">
                      4. Message{" "}
                      <a href="mailto:support@verysoul.com">
                        {" "}
                        support@verysoul.com
                      </a>{" "}
                      for assistance.
                    </p>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ScheduledetailsModal
        info={info}
        setloaderType={setloaderType}
        Index={Index}
        checkMediumInOnDemand={checkMediumInOnDemand}
      />
      <ReminderPopUp activeEvent={activeEvent} />
      <AlredyJoined />
      <ConfirmOut />
    </>
  );
}
